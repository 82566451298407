import ModalWrapper from 'shared/components/modal/ModalWrapper'
import CloseButton from 'shared/components/button/CloseButton'
import { SignUpAsRole } from 'shared/types/App'
import NormalSignUp from './NormalSignUp'
import SignInView from './SignInView'
import { ReactNode } from 'react'

type Props = {
  isAuthModalVisible: boolean
  closeAuthModal: () => void
  authMode: 'signin' | 'signup'
  signUpAsRole?: SignUpAsRole
  onSuccess?: () => void
  customizedSignUpTitle?: ReactNode
  marketingMessage?: ReactNode
}
const AuthenticationModal = (props: Props) => {
  const {
    isAuthModalVisible,
    closeAuthModal,
    authMode,
    signUpAsRole,
    onSuccess,
    customizedSignUpTitle,
    marketingMessage,
  } = props
  return (
    <ModalWrapper blurBackdrop open={isAuthModalVisible} onClose={closeAuthModal} className="auth-modal z-[12000]">
      <div className="absolute right-4 top-4 z-10">
        <CloseButton iconClassName="size-6" onClose={closeAuthModal} />
      </div>
      <div className="no-scrollbar relative flex max-h-[80vh] w-fit overflow-y-scroll rounded-3xl bg-white p-4 lg:max-h-[90vh] lg:min-w-[500px] lg:px-12 lg:py-10">
        {authMode === 'signup' && (
          <NormalSignUp
            mode="modal"
            asRole={signUpAsRole}
            onSuccess={onSuccess}
            customizedSignUpTitle={customizedSignUpTitle}
            marketingMessage={marketingMessage}
            onLogoClick={closeAuthModal}
          />
        )}
        {authMode === 'signin' && <SignInView mode="modal" onSuccess={onSuccess} />}
      </div>
    </ModalWrapper>
  )
}

export default AuthenticationModal
